// import React, { useState, useEffect } from "react";
// import useApiAxios from "../api/useApiAxios";
// import "./MarketEventFestiva.css"; // Make sure to include the updated CSS
// import { Link } from "react-router-dom";

// const MarketEventFestival = ({ marketAllContent }) => {
//   const staticImagePath = process.env.REACT_APP_IMG_URL;
//   const { getAllMarketEventFestivalApi } = useApiAxios();
//   const loginPageUrl = process.env.REACT_APP_LOGIN_PAGE_URL;

//   // State for API data
//   const [marketContentData, setMarketContentData] = useState(null);

//   // Get createdById from the prop
//   const createdById = marketAllContent?.data?.created_by[0];

//   // Fetch market content from API
//   useEffect(() => {
//     const fetchMarketContent = async () => {
//       if (createdById) {
//         const { error, data } = await getAllMarketEventFestivalApi(createdById);
//         if (!error && data) {
//           setMarketContentData(data);
//         } else {
//           setMarketContentData(null);
//         }
//       }
//     };
//     fetchMarketContent();
//   }, []);

//   const headerBannerImageUrl = `${staticImagePath}/${marketAllContent?.data?.marketContent?.headerBannerImage}`;
//   const marketContentTitle =
//     marketAllContent?.data?.marketContent?.marketContentTitle;

//   // Date formatting options
//   const dateOptions = {
//     weekday: "short",
//     month: "long",
//     day: "numeric",
//     year: "numeric"
//   };

//   return (
//     <div>
//       {/* Hero Section */}
//       <section
//         className="slider-area slider-area2"
//         style={{
//           backgroundImage: `url(${headerBannerImageUrl})`,
//           backgroundSize: "cover",
//           backgroundPosition: "center"
//         }}
//       >
//         <div className="slider-active">
//           <div className="single-slider slider-height2">
//             <div className="container">
//               <div className="row justify-content-center">
//                 <div className="col-xl-8 col-lg-11 col-md-12">
//                   <div className="hero__caption hero__caption2 text-center">
//                     <h1 data-animation="bounceIn" data-delay="0.2s">
//                       Events & Festival
//                     </h1>
//                     {/* <p
//                         data-animation="bounceIn"
//                         data-delay="0.4s"
//                         style={{ fontSize: "20px", lineHeight: "1.6" }}
//                       >
//                         {marketContentTitle?.trim() &&
//                         /[a-zA-Z0-9]/.test(
//                           marketContentTitle.replace(/<[^>]*>/g, "")
//                         ) ? (
//                           <div
//                             dangerouslySetInnerHTML={{
//                               __html: marketContentTitle,
//                             }}
//                           />
//                         ) : (
//                           "Explore a variety of markets and discover the unique products, vendors, and offers. Scroll down to check out the other markets available in your area."
//                         )}
//                       </p> */}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* Event Cards Section */}
//       <section className="events-card-section" style={{ padding: "40px 0" }}>
//         <div className="container">
//           <div className="row justify-content-center">
//             {marketContentData &&
//             marketContentData.data &&
//             marketContentData.data.length > 0 ? (
//               marketContentData.data.map((event) => (
//                 <div
//                   key={event._id}
//                   className="col-md-4 mb-4 d-flex justify-content-center"
//                 >
//                   <div
//                     className="card event-card fixed-card shadow-lg border-0"
//                     style={{
//                       transition: "transform 0.2s",
//                       overflow: "hidden",
//                       borderRadius: "10px"
//                     }}
//                   >
//                     {/* Card Header with Start and End Dates in One Row */}
//                     <div
//                       className="card-header p-3"
//                       style={{
//                         backgroundColor: "#007bff",
//                         color: "#fff",
//                         borderBottom: "2px solid rgb(96, 152, 211)"
//                       }}
//                     >
//                       <p
//                         className="mb-1"
//                         style={{ fontSize: "1rem", fontWeight: "500" }}
//                       >
//                         <strong>Start:</strong>{" "}
//                         {new Date(event.startDate).toLocaleDateString(
//                           "en-US",
//                           dateOptions
//                         )}
//                       </p>
//                       <p
//                         className="mb-0"
//                         style={{ fontSize: "1rem", fontWeight: "500" }}
//                       >
//                         <strong>End:</strong>{" "}
//                         {new Date(event.endDate).toLocaleDateString(
//                           "en-US",
//                           dateOptions
//                         )}
//                       </p>
//                     </div>

//                     {event.image && (
//                       <img
//                         src={`${staticImagePath}/${event.image}`}
//                         alt={event.eventName}
//                         className="card-img-top fixed-card-img"
//                         style={{ width: "100%" }}
//                       />
//                     )}
//                     <div className="card-body fixed-card-body p-4">
//                       <h4
//                         className="card-title"
//                         style={{
//                           fontSize: "1.75rem",
//                           fontWeight: "700"
//                         }}
//                       >
//                         {event.eventName}
//                       </h4>

//                       <div className="card-details">
//                         <p className="card-text mb-1">
//                           <strong>Type:</strong> {event.type}
//                         </p>
//                         <p className="card-text" style={{ fontSize: "1.1rem" }}>
//                           <strong>Payment:</strong>{" "}
//                           {event.paymentType === "paid"
//                             ? `To be Paid ($${event.amount})`
//                             : "Free"}
//                         </p>
//                       </div>

                     
//                       <button
//                         className="btn btn-primary"
//                         disabled={new Date(event.endDate) < new Date()}
//                       >
//                         {new Date(event.endDate) < new Date() ? (
//                           "Event End"
//                         ) : (
//                           <Link
//                             to={loginPageUrl}
//                             target="_blank"
//                             rel="noopener noreferrer"
//                           >
//                             Apply Here
//                           </Link>
//                         )}
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               ))
//             ) : (
//               <p className="text-center">No events available.</p>
//             )}
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default MarketEventFestival;
import React, { useState, useEffect } from "react";
import useApiAxios from "../api/useApiAxios";
import "./MarketEventFestiva.css"; // Make sure to include the updated CSS
import { Link } from "react-router-dom";

const MarketEventFestival = ({ marketAllContent }) => {
  const staticImagePath = process.env.REACT_APP_IMG_URL;
  const { getAllMarketEventFestivalApi } = useApiAxios();
  const loginPageUrl = process.env.REACT_APP_LOGIN_PAGE_URL;

  // State for API data
  const [marketContentData, setMarketContentData] = useState(null);

  // Get createdById from the prop
  const createdById = marketAllContent?.data?.created_by[0];

  // Fetch market content from API
  useEffect(() => {
    const fetchMarketContent = async () => {
      if (createdById) {
        const { error, data } = await getAllMarketEventFestivalApi(createdById);
        if (!error && data) {
          setMarketContentData(data);
        } else {
          setMarketContentData(null);
        }
      }
    };
    fetchMarketContent();
  }, []);

  const headerBannerImageUrl = `${staticImagePath}/${marketAllContent?.data?.marketContent?.headerBannerImage}`;

  // Date formatting options
  const dateOptions = {
    weekday: "short",
    month: "long",
    day: "numeric",
    year: "numeric"
  };

  return (
    <div>
      {/* Hero Section */}
      <section
        className="slider-area slider-area2"
        style={{
          backgroundImage: `url(${headerBannerImageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center"
        }}
      >
        <div className="slider-active">
          <div className="single-slider slider-height2">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-11 col-md-12">
                  <div className="hero__caption hero__caption2 text-center">
                    <h1 data-animation="bounceIn" data-delay="0.2s">
                      Events & Festival
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Event Cards Section */}
      <section className="events-card-section" style={{ padding: "40px 0" }}>
        <div className="container">
          <div className="row justify-content-center">
            {marketContentData?.data?.length > 0 ? (
              marketContentData.data.map((event) => {
                const isExpired = new Date(event.endDate) < new Date();
                return (
                  <div
                    key={event._id}
                    className="col-md-4 mb-4 d-flex justify-content-center"
                  >
                    <div
                      className="card event-card fixed-card shadow-lg border-0"
                      style={{
                        transition: "transform 0.2s",
                        overflow: "hidden",
                        borderRadius: "10px"
                      }}
                    >
                      <div
                        className="card-header p-3"
                        style={{
                          backgroundColor: "#007bff",
                          color: "#fff",
                          borderBottom: "2px solid rgb(96, 152, 211)"
                        }}
                      >
                        <p
                          className="mb-1"
                          style={{ fontSize: "1rem", fontWeight: "500" }}
                        >
                          <strong>Start:</strong>{" "}
                          {new Date(event.startDate).toLocaleDateString(
                            "en-US",
                            dateOptions
                          )}
                        </p>
                        <p
                          className="mb-0"
                          style={{ fontSize: "1rem", fontWeight: "500" }}
                        >
                          <strong>End:</strong>{" "}
                          {new Date(event.endDate).toLocaleDateString(
                            "en-US",
                            dateOptions
                          )}
                        </p>
                      </div>

                      {event.image && (
                        <img
                          src={`${staticImagePath}/${event.image}`}
                          alt={event.eventName}
                          className="card-img-top fixed-card-img"
                          style={{ width: "100%" }}
                        />
                      )}
                      <div className="card-body fixed-card-body p-4">
                        <h4
                          className="card-title"
                          style={{ fontSize: "1.75rem", fontWeight: "700" }}
                        >
                          {event.eventName}
                        </h4>

                        <div className="card-details">
                          <p className="card-text mb-1">
                            <strong>Type:</strong> {event.type}
                          </p>
                          <p
                            className="card-text"
                            style={{ fontSize: "1.1rem" }}
                          >
                            <strong>Payment:</strong>{" "}
                            {event.paymentType === "paid"
                              ? `To be Paid ($${event.amount})`
                              : "Free"}
                          </p>
                        </div>

                        <button
                          className="btn"
                          style={{
                            backgroundColor: isExpired ? "red" : "#007bff",
                            color: "#fff"
                          }}
                          disabled={isExpired}
                        >
                          {isExpired ? (
                            "Expired"
                          ) : (
                            <Link
                              to={loginPageUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: "#fff", textDecoration: "none" }}
                            >
                              Apply Here
                            </Link>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="text-center">No events available.</p>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default MarketEventFestival;
