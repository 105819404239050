import React, { useEffect, useState } from "react";
import useApiAxios from "../api/useApiAxios";
import { useSearchParams } from "react-router-dom";
import VendorModal from "../component/modal/vendorModal";

const MarketMap = (marketMapData) => {
  const { getMarketVendorMapApi, getVendorAfterDateChangeApi } = useApiAxios();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [marketData, setMarketData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(""); // Default value for "Select Date"
  const [selectedMapImage, setSelectedMapImage] = useState(null);
  const [selectedMapImageAddress, setSelectedMapImageAddress] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(null);

  const staticImagePath = process.env.REACT_APP_IMG_URL;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [vendor, setVendor] = useState([]);

  useEffect(() => {
    const fetchVendors = async () => {
      try {
        const response = await getMarketVendorMapApi(id);
        setMarketData(response.data.data);

        // Set the default map image and address on initial load
        if (response.data.data.marketData.marketDate.length > 0) {
          setSelectedMapImage(response.data.data.marketData.mapImage);
          setSelectedMapImageAddress(
            response.data.data.marketData.marketDate[0]?.marketMapAddress
          );
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (id) {
      fetchVendors();
    }
  }, [id]);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleDateChange = async (event) => {
    const selectedDateId = event.target.value;
    setSelectedDate(selectedDateId);

    if (selectedDateId === "") {
      // Reset to default if no date is selected
      setSelectedMapImage(marketData.marketData.mapImage);
      setSelectedMapImageAddress(
        marketData.marketData.marketDate[0]?.marketMapAddress
      ); // Default address
      setVendor([]); // Clear vendors if no date is selected
    } else {
      try {
        const getVendorAfterDateChange = await getVendorAfterDateChangeApi(
          selectedDateId
        );
        setVendor(getVendorAfterDateChange?.data?.data || []);

        // Find the selected date's data
        const selectedDateData = marketData.marketData.marketDate.find(
          (dateItem) => dateItem._id === selectedDateId
        );

        if (selectedDateData) {
          setSelectedMapImage(
            selectedDateData.mapImages || marketData.marketData.mapImage
          );
          setSelectedMapImageAddress(
            selectedDateData.marketMapAddress || "Address not available"
          );
        }
      } catch (error) {
        console.error("Error fetching vendors after date change:", error);
      }
    }
  };

  const handleVendorClick = (vendor) => {
    setSelectedVendor(vendor);
    setIsModalOpen(true); // Open modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedVendor(null); // Clear selected vendor
  };

  const headerBannerImageUrl = `${staticImagePath}/${marketMapData?.marketMapData?.marketContent?.headerBannerImage}`;

  // Sort market dates in ascending order before mapping them to options
  const sortedMarketDates = marketData?.marketData?.marketDate.sort((a, b) => {
    return new Date(a.date) - new Date(b.date);
  });
  const marketMapTitle =
    marketMapData?.marketMapData?.marketContent?.marketMapTitle;

  return (
    <div>
      <section
        className="slider-area slider-area2"
        style={{
          backgroundImage: `url(${headerBannerImageUrl})`, // Set the background image dynamically
          backgroundSize: "cover",
          backgroundPosition: "center"
        }}
      >
        <div className="slider-active">
          {/* Single Slider */}
          <div className="single-slider slider-height2">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-10">
                  <div className="hero__caption hero__caption2 text-center">
                    <h1 data-animation="bounceIn" data-delay="0.2s">
                      Market Maps of{" "}
                      <strong>{marketData?.marketData?.name}</strong>{" "}
                      {/* Makes the market name bold */}
                    </h1>

                    <p
                      data-animation="bounceIn"
                      data-delay="0.4s"
                      style={{ fontSize: "20px", lineHeight: "1.6" }}
                    >
                      {marketMapTitle?.trim() &&
                      /[a-zA-Z0-9]/.test(
                        marketMapTitle.replace(/<[^>]*>/g, "")
                      ) ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: marketMapTitle
                          }}
                        />
                      ) : (
                        "View the detailed map for each market and easily find locations, vendor spots, and key facilities. Scroll down to explore the map for your selected market."
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our-services form-contact">
        <div className="container">
          {/* Card Open */}
          <div className="card mb-5">
            <div className="card-header">
              <h2 className="font-weight-bold mb-20">
                {marketData?.marketData?.name}
              </h2>
              <div className="row">
                {/*/Col*/}
                <div className="col-md-3 col-sm-4 mb-3">
                  <label>Select Date</label>
                  <select
                    className="form-control"
                    value={selectedDate}
                    onChange={handleDateChange}
                  >
                    <option value="">Select Date</option>
                    {sortedMarketDates &&
                      sortedMarketDates.map((dateItem) => (
                        <option key={dateItem._id} value={dateItem._id}>
                          {formatDate(dateItem.date)}
                        </option>
                      ))}
                  </select>
                </div>
                {/*/Col*/}
              </div>
            </div>
            <div className="card-body">
              {/* Only display map data when a date is selected */}
              {selectedDate ? (
                <div className="map-container">
                  {/* Display the address dynamically */}
                  <span>
                    Address:{" "}
                    {selectedMapImageAddress || "Address not available"}
                  </span>
                  <img
                    src={`${staticImagePath}/${selectedMapImage}`}
                    alt="Market Map"
                    className="w-100"
                  />
                  <div className="map-data-overlay mt-4">
                    {vendor.length > 0 ? (
                      vendor.map((vendor, index) => (
                        <a
                          key={vendor?.vendor?._id}
                          href="#"
                          className="genric-btn primary-border circle m-1"
                          onClick={(e) => {
                            e.preventDefault();
                            handleVendorClick(vendor?.vendor); // Show vendor details in modal
                          }}
                        >
                          <span className="badge badge-warning badge-pill mr-2">
                            {index + 1}
                          </span>
                          {vendor?.vendor?.businessName}
                        </a>
                      ))
                    ) : (
                      <p>No vendors available for this date.</p>
                    )}
                  </div>
                </div>
              ) : (
                <div className="text-center">
                  <h3>
                    Please select a date to view the market map and vendors.
                  </h3>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      <VendorModal
        vendor={selectedVendor}
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      />
    </div>
  );
};

export default MarketMap;
