import React, { useState, useEffect } from "react";
import useApiAxios from "../api/useApiAxios";
import "./MarketContent.css"; // Import the CSS file for styling

const MarketContent = ({ marketAllContent }) => {
  const staticImagePath = process.env.REACT_APP_IMG_URL;
  const { getAllMarketContentApi } = useApiAxios();

  // State to manage modal visibility and selected market
  const [showModal, setShowModal] = useState(false);
  const [selectedMarket, setSelectedMarket] = useState(null);
  const [marketContentData, setMarketContentData] = useState(null);

  const createdById = marketAllContent?.data?.created_by[0];

  // Open modal to view selected market
  const openModal = (marketIndex) => {
    setSelectedMarket(marketIndex);
    setShowModal(true);
  };

  // Close modal
  const closeModal = () => {
    setShowModal(false);
    setSelectedMarket(null);
  };

  // Fetch Market Content
  useEffect(() => {
    const fetchMarketContent = async () => {
      if (createdById) {
        // console.warn("Fetching data for createdById:", createdById);
        const { error, data } = await getAllMarketContentApi(createdById);
        if (!error && data) {
          setMarketContentData(data); // Set the data if there was no error
        } else {
          setMarketContentData(null); // Handle the case where the API returns an error
        }
      }
    };

    fetchMarketContent();
  }, [createdById]); // Re-run when createdById changes

  const headerBannerImageUrl = `${staticImagePath}/${marketAllContent?.data?.marketContent?.headerBannerImage}`;

  const marketContentTitle =
    marketAllContent?.data?.marketContent?.marketContentTitle;
  // console.warn("marketContentTitle",marketAllContent?.data?.marketContent?.marketContentTitle )

  console.warn(
    "SSSSSSS{SSSSSS:::::::::::::::::::::::::::::::::::::::::::::::",
    marketContentData
  );
  return (
    <div>
      {/* Hero section */}
      <section
        className="slider-area slider-area2"
        style={{
          backgroundImage: `url(${headerBannerImageUrl})`, // Set the background image dynamically
          backgroundSize: "cover",
          backgroundPosition: "center"
        }}
      >
        <div className="slider-active">
          <div className="single-slider slider-height2">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-8 col-lg-11 col-md-12">
                  <div className="hero__caption hero__caption2 text-center">
                    <h1 data-animation="bounceIn" data-delay="0.2s">
                      Meet Our Markets
                    </h1>
                    <p
                      data-animation="bounceIn"
                      data-delay="0.4s"
                      style={{ fontSize: "20px", lineHeight: "1.6" }}
                    ></p>

                    <p
                      data-animation="bounceIn"
                      data-delay="0.4s"
                      style={{ fontSize: "20px", lineHeight: "1.6" }}
                    >
                      {marketContentTitle?.trim() &&
                      /[a-zA-Z0-9]/.test(
                        marketContentTitle.replace(/<[^>]*>/g, "")
                      ) ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: marketContentTitle
                          }}
                        />
                      ) : (
                        "Explore a variety of markets and discover the unique products, vendors, and offers. Scroll down to check out the other markets available in your area."
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Heading for Markets */}
      <div className="markets-header">
        <h2>Markets</h2>
        <p>Owned by the Market Owner</p>
      </div>

      <div className="mt-2">
        {/* Iterate through each market */}
        <div className="market-cards-grid">
          {marketContentData?.data?.map((market, marketIndex) => (
            <div
              key={marketIndex}
              className="market-card"
              onClick={() => openModal(marketIndex)} // Handle click to show modal
              style={{ cursor: "pointer" }}
            >
              <div className="card-image">
                <img
                  // src={`${staticImagePath}/${market.marketContent?.bannerImage}`}
                  src={`${staticImagePath}/${market?.image}`}
                  alt="Market Banner"
                  className="market-card-image"
                />
              </div>
              <div className="card-title">
                <h3>{market.name}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal to show the details */}
      {showModal && selectedMarket !== null && marketContentData && (
        <div className="modal-overlay">
          <div className="modal-content">
            {/* Close button */}
            <button className="modal-close-btn" onClick={closeModal}>
              Close
            </button>

            <div className="modal-header">
              <h2>{marketContentData.data[selectedMarket].name}</h2>
            </div>

            {/* Modal Body - Display Market Details */}
            <div className="modal-body">
              {/* Main Title */}
              <div className="main-title">
                <h3 className="green-heading">Title:</h3>{" "}
                {/* Apply green-heading class here */}
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      marketContentData.data[selectedMarket].marketContent
                        ?.mainTitle
                  }}
                  className="mt-4"
                />
              </div>

              {/* Title */}
              <div className="title">
                <h3 className="green-heading">Title:</h3>{" "}
                {/* Apply green-heading class here */}
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      marketContentData.data[selectedMarket].marketContent
                        ?.title
                  }}
                  className="mt-4"
                />
              </div>

              {/* Market Description */}
              <div className="market-description">
                <h3 className="green-heading">Description:</h3>{" "}
                {/* Apply green-heading class here */}
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      marketContentData.data[selectedMarket].marketContent
                        ?.description
                  }}
                />
              </div>

              {/* Footer Description */}
              <div className="footer-description">
                <h3 className="green-heading">Footer Description:</h3>{" "}
                {/* Apply green-heading class here */}
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      marketContentData.data[selectedMarket].marketContent
                        ?.footerDescription
                  }}
                />
              </div>

              {/* Terms and Conditions */}
              <div className="terms-conditions">
                <h3 className="green-heading">Terms and Conditions:</h3>{" "}
                {/* Apply green-heading class here */}
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      marketContentData.data[selectedMarket].marketContent
                        ?.termsAndConditions
                  }}
                />
              </div>

              {/* Social Media Links */}
              <div className="social-links">
                {(marketContentData.data[selectedMarket].marketContent
                  ?.facebookUrl &&
                  marketContentData.data[
                    selectedMarket
                  ].marketContent.facebookUrl.trim() !== "") ||
                (marketContentData.data[selectedMarket].marketContent
                  ?.instaUrl &&
                  marketContentData.data[
                    selectedMarket
                  ].marketContent.instaUrl.trim() !== "") ||
                (marketContentData.data[selectedMarket].marketContent
                  ?.twitterUrl &&
                  marketContentData.data[
                    selectedMarket
                  ].marketContent.twitterUrl.trim() !== "") ||
                (marketContentData.data[selectedMarket].marketContent
                  ?.tiktokUrl &&
                  marketContentData.data[
                    selectedMarket
                  ].marketContent.tiktokUrl.trim() !== "") ? (
                  <>
                    <h3 className="green-heading">Social Media Links:</h3>
                    {marketContentData.data[selectedMarket].marketContent
                      ?.facebookUrl &&
                      marketContentData.data[
                        selectedMarket
                      ].marketContent.facebookUrl.trim() !== "" && (
                        <a
                          href={
                            marketContentData.data[selectedMarket].marketContent
                              .facebookUrl
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className="social-link"
                        >
                          Facebook
                        </a>
                      )}
                    {marketContentData.data[selectedMarket].marketContent
                      ?.instaUrl &&
                      marketContentData.data[
                        selectedMarket
                      ].marketContent.instaUrl.trim() !== "" && (
                        <a
                          href={
                            marketContentData.data[selectedMarket].marketContent
                              .instaUrl
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className="social-link"
                        >
                          Instagram
                        </a>
                      )}
                    {marketContentData.data[selectedMarket].marketContent
                      ?.twitterUrl &&
                      marketContentData.data[
                        selectedMarket
                      ].marketContent.twitterUrl.trim() !== "" && (
                        <a
                          href={
                            marketContentData.data[selectedMarket].marketContent
                              .twitterUrl
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className="social-link"
                        >
                          Twitter
                        </a>
                      )}
                    {marketContentData.data[selectedMarket].marketContent
                      ?.tiktokUrl &&
                      marketContentData.data[
                        selectedMarket
                      ].marketContent.tiktokUrl.trim() !== "" && (
                        <a
                          href={
                            marketContentData.data[selectedMarket].marketContent
                              .tiktokUrl
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className="social-link"
                        >
                          TikTok
                        </a>
                      )}
                  </>
                ) : null}
              </div>

              {/* Subcategories Section */}
              <div className="subcategories">
                <h3 className="green-heading">Categories:</h3>
                {marketContentData.data[selectedMarket].category.length ? (
                  <div className="category-wrapper">
                    {marketContentData.data[selectedMarket].category.map(
                      (cat, index) => (
                        <div key={index} className="category-card">
                          <span className="category-name">{cat.name}</span>
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  <p className="no-categories-message">
                    No Categories available
                  </p>
                )}
              </div>
              

              <div className="subcategories">
                <h3 className="green-heading">Sub Categories:</h3>
                {marketContentData.data[selectedMarket].category.length ? (
                  <div className="category-wrapper">
                    {marketContentData.data[selectedMarket].subCategory.map(
                      (cat, index) => (
                        <div key={index} className="category-card">
                          <span className="category-name">{cat.name}</span>
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  <p className="no-categories-message">
                    No Sub Categories available
                  </p>
                )}
              </div>

              <div className="market-dates">
                <h3 className="green-heading">Market Dates:</h3>
                {marketContentData.data[selectedMarket].marketDate.length ? (
                  <div className="market-date-wrapper">
                    {marketContentData.data[selectedMarket].marketDate.map(
                      (dateInfo, dateIndex) => {
                        const formattedDate = new Date(
                          dateInfo.date
                        ).toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "long",
                          year: "numeric"
                        });

                        return (
                          <div key={dateIndex} className="market-date-card">
                            <span className="market-date-item">
                              {formattedDate}
                            </span>
                          </div>
                        );
                      }
                    )}
                  </div>
                ) : (
                  <p className="no-dates-message">No market dates available</p>
                )}
              </div>

              {/* Sections */}
              <div className="market-sections">
                <h3 className="green-heading">Sections:</h3>{" "}
                {/* Apply green-heading class here */}
                <div className="section-columns">
                  {marketContentData.data[selectedMarket].marketContent?.section
                    .length ? (
                    marketContentData.data[
                      selectedMarket
                    ].marketContent.section.map((section, index) => (
                      <div key={index} className="section-column">
                        <img
                          src={`${staticImagePath}/${section.image}`}
                          alt={section.title}
                          className="section-image"
                        />
                        <h4
                          dangerouslySetInnerHTML={{
                            __html: section.description
                          }}
                        ></h4>
                        <div
                          className="section-description"
                          dangerouslySetInnerHTML={{
                            __html: section.description
                          }}
                        />
                      </div>
                    ))
                  ) : (
                    <p>No sections available</p>
                  )}
                </div>
              </div>

              <div className="market-content-title">
                <h3 className="green-heading">Market Content Title:</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      marketContentData.data[selectedMarket].marketContent
                        ?.marketContentTitle
                  }}
                />
              </div>

              {/* Meet Our Vendor Title */}
              <div className="meet-our-vendor-title">
                <h3 className="green-heading">Meet Our Vendor Title:</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      marketContentData.data[selectedMarket].marketContent
                        ?.meetOurVendorTitle
                  }}
                />
              </div>

              {/* Market Map Title */}
              <div className="market-map-title">
                <h3 className="green-heading">Market Map Title:</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      marketContentData.data[selectedMarket].marketContent
                        ?.marketMapTitle
                  }}
                />
              </div>

              {/* Apply Here Title */}
              <div className="apply-here-title">
                <h3 className="green-heading">Apply Here Title:</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      marketContentData.data[selectedMarket].marketContent
                        ?.applyHereTitle 
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MarketContent;
